import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/product',
    name: 'product',
    component: () => import(/* webpackChunkName: "about" */ '../views/product.vue')
  },
  {
    path: '/classic_case',
    name: 'classic_case',
    component: () => import(/* webpackChunkName: "about" */ '../views/classic_case.vue')
  },
  {
    path: '/talents',
    name: 'talents',
    component: () => import(/* webpackChunkName: "about" */ '../views/talents.vue')
  },
  {
    path: '/contact_us',
    name: 'contact_us',
    component: () => import(/* webpackChunkName: "about" */ '../views/contact_us.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
