
import { defineComponent, ref } from 'vue';
import HomeHeader from '@/components/header.vue';
import HomeFooter from '@/components/footer.vue';

export default defineComponent({
	name: 'HomeView',
	components: {
		HomeHeader,
		HomeFooter
	},
	setup() {
		let parentValue = ref('首页');
		return {
			parentValue
		};
	}
});
