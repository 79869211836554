<template>
	<div class="home_service_hotline"><span @click="showModal = true">服务热线</span></div>
	<div class="home_nav">
		<img src="../assets/img/logo.png" alt="" />
		<div class="home_navlist">
			<router-link  :style="{ color: value == '首页' ? '#df0f1b' : '#000' }" to="/">首页</router-link>
			<router-link  :style="{ color: value == '走进天一' ? '#df0f1b' : '#000' }" to="/about">走进天一</router-link>
			<router-link :style="{ color: value == '产品服务' ? '#df0f1b' : '#000' }" to="/product">产品服务</router-link>
			<router-link :style="{ color: value == '经典案例' ? '#df0f1b' : '#000' }" to="/classic_case">经典案例</router-link>
			<router-link :style="{ color: value == '人才资源' ? '#df0f1b' : '#000' }" to="/talents">人才资源</router-link>
			<router-link :style="{ color: value == '联系我们' ? '#df0f1b' : '#000' }" to="/contact_us">联系我们</router-link>
		</div>
		<div background-color="grey">
			<div class="back" v-if="showModal" @click="showModal = false"></div>
			<div class="pop" v-if="showModal">
				<img src="../assets/img/rexian.png" alt="" />
				<p>服务热线：19358293631</p>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent, reactive, ref } from 'vue';

export default {
	name: 'HomeView',
	components: {},
	props: ['value'],
	setup() {
		let showModal = ref(false);

		return {
			showModal
		};
	}
};
</script>

<style lang="scss">
	.hoverred{
		color: #a40000;
	}
.home_service_hotline {
	height: 2.5rem;
	background-color: #000;
	padding: 0 6%;
	color: #fff;
	line-height: 2.5rem;
	span {
		float: right;
		cursor: pointer;
	}
}
.home_nav {
	margin: 0 6%;
	height: 5rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.home_navlist {
		width: 50%;
		font-weight: bold;
		display: flex;
		align-items: center;
		justify-content: space-between;
		a:hover {
			color: #a40000;
		}
	}
}
.back {
	background-color: #000;
	opacity: 0.3;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.pop {
	// background-color: #fff;
	position: fixed;
	top: 100px;
	left: 300px;
	z-index: 2;
	img {
		width: 100%;
		height: 100%;
	}
	p {
		position: absolute;
		bottom: 6.6rem;
		left: 50%;
		margin-left: -185px;
		width: 370px;
		height: 70px;
		color: #fff;
		font-weight: bold;
		background-color: #a40000;
		font-size: 1.5rem;
		border-radius: 10px;
		text-align: center;
		line-height: 70px;
	}
}
</style>
